import { useEffect, useState } from "react"
import styled from "styled-components"

import Footer from "./footer"
import Header from "./header"
import SEO, { Props as SEOProps } from "./seo"

const Container = styled.div`
  width: 100%;
  max-width: calc(1500px + 20%);
  margin: 0 auto;
`
const Main = styled.main`
  padding-top: 2rem;
`
const Content = styled.div`
  padding-bottom: 20px;
`
const H1 = styled.h1`
  text-align: center;
  text-transform: uppercase;
  font-size: 1.5rem;
  text-decoration: underline;
  margin-bottom: 2rem;
`

interface Props {
  active?: number
}
const Layout: React.FC<Props & SEOProps> = ({ children, title, active, ...props }) => {
  const [shadow, setShadow] = useState(false)
  const headerShadow = () => {
    setShadow(window.pageYOffset !== 0)
  }

  useEffect(() => {
    window.addEventListener("scroll", headerShadow)
    return () => {
      window.removeEventListener("scroll", headerShadow)
    }
  })

  return (
    <Container>
      <SEO {...props} />
      <Header active={active} shadow={shadow} />
      <Main>
        {title && !active && active !== 0 && <H1>{title}</H1>}
        <Content>{children}</Content>
      </Main>
      <Footer />
    </Container>
  )
}

export default Layout
