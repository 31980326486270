import { Link, graphql, useStaticQuery } from "gatsby"
import slugify from "slugify"
import styled, { css } from "styled-components"

import { getCreationSlug } from "src/helpers/slug"

const Container = styled.header<{ $shadow?: boolean }>`
  background-color: #fff;
  /* left: 0; */
  /* position: fixed; */
  /* top: 0; */
  /* transition: box-shadow 0.3s ease-in-out; */
  width: 100%;
  z-index: 1;
  /* ${({ $shadow }) => ($shadow ? `box-shadow: 0 5px 5px rgba(57, 63, 72, 0.2);` : ``)} */
`
const Content = styled.div`
  max-width: 1500px;
  display: flex;
  margin: 0 auto;

  @media (max-width: 700px) {
    display: block;
  }
`
const Menu = styled.ul`
  list-style: none;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  width: 100%;
`
const BaseLink = css`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #000000;
  text-decoration: none;
  letter-spacing: 0.15em;
  position: relative;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: bold;
  position: relative;
`
const Logo = styled.div`
  text-align: center;
  text-transform: uppercase;
  padding: 8px 0 0;

  a {
    text-decoration: none;
    font-size: 2rem;
    font-weight: bold;
    color: #222831;
  }

  label {
    font-size: 0.7rem;
    display: block;
  }
`
const Separator = styled.div`
  width: 40%;
  height: 1px;
  background-color: #22283133;
  margin: 8px auto;
`
const Li = styled.li<{ $active?: boolean }>`
  width: 25%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  @media (max-width: 700px) {
    height: 30px;
    width: 50%;
  }

  a {
    ${BaseLink}
  }
  ${({ $active }) =>
    $active
      ? css`
          a:after {
            width: 100%;
            left: 0;
          }
        `
      : ``}
`

const DropDownLabel = styled.div`
  ${BaseLink}

  &:hover,
  &:active,
  &:focus {
    .dropdownContent,
    .dropdownContentShadow {
      display: block;
    }
  }
`

const DropdownContent = styled.div<{ $shadow?: boolean }>`
  display: none;
  position: absolute;
  background-color: #ffffff;
  top: 30px;
  left: 0px;
  right: 0px;
  min-width: 150px;
  text-align: center;
  ${({ $shadow }) => ($shadow ? `box-shadow: 0 5px 5px rgba(57, 63, 72, 0.2);` : ``)}
  z-index: 1;

  div {
    margin: 15px 5px;
  }
`

interface Props {
  active?: number
  shadow: boolean
}

const Header = ({ active, shadow }: Props) => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      allContentfulCreation {
        edges {
          node {
            title
            rechercheTransmission
          }
        }
      }
    }
  `)

  return (
    <Container $shadow={shadow}>
      <Logo>
        <Link to={`/`}>Hinterland</Link>
        <br />
        <label>L’arrière pays</label>
        <Separator />
      </Logo>
      <Content>
        <Menu>
          <Li $active={active === 1}>
            <Link to={`/presentation`}>Présentation</Link>
          </Li>
          <Li $active={active === 2}>
            <DropDownLabel>
              Créations
              <DropdownContent $shadow={shadow} className="dropdownContent">
                {data.allContentfulCreation.edges
                  .filter(({ node }) => !node.rechercheTransmission)
                  .map(({ node }) => (
                    <div key={`/${slugify(node.title)}`}>
                      <Link to={`/${getCreationSlug(node.title)}`}>{node.title}</Link>
                    </div>
                  ))}
              </DropdownContent>
            </DropDownLabel>
          </Li>
          <Li $active={active === 2}>
            <DropDownLabel>
              Recherche-transmission
              <DropdownContent $shadow={shadow} className="dropdownContent">
                {data.allContentfulCreation.edges
                  .filter(({ node }) => node.rechercheTransmission)
                  .map(({ node }) => (
                    <div key={`/${slugify(node.title)}`}>
                      <Link to={`/${getCreationSlug(node.title)}`}>{node.title}</Link>
                    </div>
                  ))}
              </DropdownContent>
            </DropDownLabel>
          </Li>
          <Li $active={active === 3}>
            <Link to={`/agenda`}>Agenda</Link>
          </Li>
        </Menu>
      </Content>
    </Container>
  )
}

export default Header
